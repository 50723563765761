var InfiniteScroll = require('infinite-scroll');

$(document).ready(function(){
  if ($("nav.pagination a[rel=next]")[0]) {
    new InfiniteScroll(".inf-scrl-container", {
      path: "nav.pagination a[rel=next]",
      append: ".inf-scrl-append",
      hideNav: "nav.pagination",
      history: false,
      prefill: true
    });
  }
});
